import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);

    this.hamburger = el.querySelector('#hamburger-icon');
    // this.parentItem = el.querySelector('.has-children > a');
    this.hamburgerMenu = el.querySelector('.hamburger-menu');
    this.body = document.getElementById('body');

    this.hamburger.onclick = (e) => {
      e.preventDefault();
      this.hamburger.classList.toggle('active');
      this.body.classList.toggle('overflow-hidden');
      el.classList.toggle('active');
      this.hamburgerMenu.classList.remove('active');
    };

    // this.parentItem.onclick = (e) => {
    //   e.preventDefault();
    //   this.hamburgerMenu.classList.toggle('active');
    // };
  }
}

Header.className = 'Header';
export default Header;
