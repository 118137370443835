import Base from '../../_config/base';

class Kpi extends Base {
  constructor(el) {
    super(el);

    if (window.innerWidth < 1440) {
      el.onclick = () => {
        // console.log('card33', el);
        el.classList.toggle('card-slide');
      };
    }
  }
}

Kpi.className = 'Kpi';
export default Kpi;
