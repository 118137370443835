import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Slider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      centeredSlides: true,
      initialSlide: 1,
      spaceBetween: 48,
      slidesPerView: 1.3,
      breakpoints: {
        320: {
          slidesPerView: 1.3,
          spaceBetween: 24,
        },
        1440: {
          spaceBetween: 48,
        },
      },
      pagination: {
        el: '.swiper-slider-pagination',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Slider.className = 'Slider';
export default Slider;
