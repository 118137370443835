import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Mapslider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper__map');

    // const navButton0 = document.getElementById('swiper__nav_btn1');
    // const navButton1 = document.getElementById('swiper__nav_btn2');
    // const navButton2 = document.getElementById('swiper__nav_btn3');
    // const navButton3 = document.getElementById('swiper__nav_btn4');

    const navButtons = el.querySelectorAll('.swiper__nav_btn');

    console.log('navButtons', navButtons);

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      centeredSlides: true,
      initialSlide: 0,
      spaceBetween: 48,
      slidesPerView: 1,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        1440: {
          spaceBetween: 48,
        },
      },
      pagination: {
        el: '.swiper-mapslider-pagination',
        clickable: true,
        renderBullet: (index, className) => `<div class="swiper__nav_btn-custom ${className}">
          ${navButtons[index].innerHTML}
        </div>`,
      },
      // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Mapslider.className = 'Mapslider';
export default Mapslider;
